import antdIdID from "antd/es/locale/id_ID";
import enMsg from "../locales/id_ID.json";

const IdLang = {
  antd: antdIdID,
  locale: "id-ID",
  messages: {
    ...enMsg,
  },
};
export default IdLang;
