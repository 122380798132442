import FetchInterceptor from "configs/FetchInterceptor";

class CustomersServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/customers/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getByIds(requestBody) {
    const apiUrl = "/core/v1/customers/get/data";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/customers/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/customers/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/core/v1/customers/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async search(requestBody) {
    const apiUrl = "/core/v2/customer/search";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addOptionNonMember(requestBody) {
    const apiUrl = "/core/v2/customer/nonmember/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async batchUpload(requestBody) {
    const apiUrl = "/core/v2/customers/batch/upload";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async export(requestBody) {
    const apiUrl = "/data/v1/export/customers/xlsx";
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }
}

export default CustomersServices;
