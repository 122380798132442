/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AdminLayout from "layouts/admin-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, ADMIN_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import Loading from "components/shared-components/Loading";
import ReactGA from "react-ga4";

function RouteInterceptor({ isRedirectToLogin, children, isFirstLogin, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          !isFirstLogin ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: `${AUTH_PREFIX_PATH}/change-password`,
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: isRedirectToLogin ? AUTH_PREFIX_PATH : APP_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, location, direction, activeUser, currentUser } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
  }, [location.pathname, location.search]);

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor
            path={APP_PREFIX_PATH}
            isRedirectToLogin={true}
            isAuthenticated={activeUser && currentUser}
            isFirstLogin={currentUser && currentUser.employee.is_first_login}
          >
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
          <RouteInterceptor
            path={ADMIN_PREFIX_PATH}
            isAuthenticated={
              activeUser &&
              currentUser &&
              currentUser.roleAdmin &&
              currentUser.accessMenuAdmin?.length
            }
            isFirstLogin={currentUser && currentUser.employee.is_first_login}
          >
            <AdminLayout direction={direction} location={location} />
          </RouteInterceptor>
          <Suspense fallback={<Loading cover="page" />}>
            <Route path={`*`} component={lazy(() => import(`./auth-views/page-not-found`))} />
          </Suspense>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth, user }) => {
  const { locale, direction } = theme;
  const { activeUser } = auth;
  const { currentUser } = user;
  return { locale, direction, activeUser, currentUser };
};

export default withRouter(connect(mapStateToProps)(Views));
