import FetchInterceptor from "configs/FetchInterceptor";

class StrataServices {
  static async getAllCustomerLevel(requestBody) {
    const apiUrl = "/core/v1/customer-level/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async getAllCustomerLevelSellingPrice(requestBody) {
    const apiUrl = "/core/v1/selling-price/customer-level/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async exportCustomerLevelSellingPrice(requestBody) {
    const apiUrl = "/data/v1/export/selling-prices/customer-level/selling-price-generate/xlsx";
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }

  static async uploadCustomerLevelSellingPrice(requestBody) {
    const apiUrl = "/core/v1/customer-level/upload";
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }

  static async addCustomerLevel(requestBody) {
    const apiUrl = "/core/v1/customer-level/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default StrataServices;
