import FetchInterceptor from "configs/FetchInterceptor";

class SellingPricesServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v2/selling-prices/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/selling-prices/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/core/v1/selling-prices/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getExcelTemplate(requestBody) {
    const apiUrl = "/data/v1/export/selling-prices/xlsx";
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }

  static async updateExcel(requestBody) {
    const apiUrl = "/core/v1/selling-prices/excel/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async batchUpdate(requestBody) {
    const apiUrl = "/core/v1/selling-prices/batch/updates";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default SellingPricesServices;
